:where(.Avatar) {
  display: inline-block;
  /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
  width: var(--avatarSize-regular);
  /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
  height: var(--avatarSize-regular);
  overflow: hidden; /* Ensure page layout in Firefox should images fail to load */
  /* stylelint-disable-next-line primer/typography */
  line-height: 1;
  vertical-align: middle;
  border-radius: 50%;
  /* stylelint-disable-next-line primer/box-shadow */
  box-shadow: 0 0 0 1px var(--avatar-borderColor);

  &:where([data-square]) {
    border-radius: var(--borderRadius-medium);
  }

  &:where([data-responsive]) {
    @media screen and (--viewportRange-narrow) {
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      width: var(--avatarSize-narrow);
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      height: var(--avatarSize-narrow);
    }

    @media screen and (--viewportRange-regular) {
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      width: var(--avatarSize-regular);
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      height: var(--avatarSize-regular);
    }

    @media screen and (--viewportRange-wide) {
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      width: var(--avatarSize-wide);
      /* stylelint-disable-next-line csstools/value-no-unknown-custom-properties */
      height: var(--avatarSize-wide);
    }
  }
}
